// extracted by mini-css-extract-plugin
export var actions = "bv_bQ";
export var articleMultipleChoiceCTA = "bv_dP";
export var body = "bv_dR";
export var bodyContainer = "bv_dQ";
export var illustration = "bv_dT";
export var selected = "bv_dX";
export var subtitle = "bv_dW";
export var textContent = "bv_dV";
export var tile = "bv_dS";
export var wrapper = "bv_bl";